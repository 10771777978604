import React from "react";
import { getUserId, getUserEmail } from "../utils/userUtils";

const PricingTable = ({ user }) => {
  const userEmail = getUserEmail(user);
  const userId = getUserId(user);

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `
          <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
          <stripe-pricing-table
            pricing-table-id="prctbl_1Qo4t7LCKcqaoophvi0gCEhg"
            publishable-key="pk_live_51O0Hx7LCKcqaoopheYNbL5hukbPWFLCWZ2BBEXKavp7r80XZCcyl3jMh8a939j82wSoOqKF49cmkJvbHoUnW8ic400fWuwttmV"
            customer-email="${userEmail}"
            client-reference-id="${userId}">
          </stripe-pricing-table>
        `,
      }}
    />
  );
};

export default PricingTable;
