/**
 * Utility functions for handling user data
 */

/**
 * Gets the user's ID from either Google sign-in or Cognito attributes
 * @param {Object} user - The user object from useAuthenticator
 * @returns {string|null} The user ID or null if not available
 */
export const getUserId = (user) => {
  if (!user) return null;
  // For Google sign-in, sub is in the idToken payload
  if (user.signInUserSession?.idToken?.payload?.sub) {
    return user.signInUserSession.idToken.payload.sub;
  }
  // Fallback to traditional attributes
  return user.attributes?.sub || user.username || null;
};

/**
 * Gets the user's email from either Google sign-in or Cognito attributes
 * @param {Object} user - The user object from useAuthenticator
 * @returns {string|null} The user's email or null if not available
 */
export const getUserEmail = (user) => {
  if (!user) return null;
  // For Google sign-in, email is in the idToken payload
  if (user.signInUserSession?.idToken?.payload?.email) {
    return user.signInUserSession.idToken.payload.email;
  }
  // Fallback to traditional attributes
  return user.attributes?.email || user.username || null;
};

/**
 * Checks if the user is authenticated
 * @param {Object} user - The user object from useAuthenticator
 * @returns {boolean} True if the user is authenticated
 */
export const isAuthenticated = (user) => {
  return !!user && (!!getUserId(user) || !!getUserEmail(user));
};
