import React, { useState, useRef } from "react";
import { FaMicrophone, FaStop, FaTrash, FaPaperPlane } from "react-icons/fa";
import { Storage } from "aws-amplify";
import { checkAwsCredentials } from "../../utils/websocket";

// Configure Storage with the correct region and level
Storage.configure({
  region: "us-east-1",
  bucket: "sapienslab-storage-dev-user-files",
  level: "public",
  customPrefix: {
    public: "",
  },
});

const AudioRecorder = ({ onAudioUpload, userId, chatBotId }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [audioBlob, setAudioBlob] = useState(null);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);
  const timerRef = useRef(null);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      chunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunksRef.current.push(e.data);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(chunksRef.current, { type: "audio/webm" });
        setAudioBlob(blob);
        stream.getTracks().forEach((track) => track.stop());
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
      setRecordingTime(0);

      timerRef.current = setInterval(() => {
        setRecordingTime((prev) => prev + 1);
      }, 1000);
    } catch (error) {
      console.error("Error accessing microphone:", error);
      alert(
        "Error accessing microphone. Please ensure you have granted microphone permissions."
      );
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      clearInterval(timerRef.current);
    }
  };

  const cancelRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      clearInterval(timerRef.current);
    }
    setAudioBlob(null);
    setRecordingTime(0);
  };

  const uploadAudio = async () => {
    if (!audioBlob) return;

    const timestamp = Date.now();
    const s3Key = `public/users/${userId}/chatbots/${chatBotId}/audio/${timestamp}.webm`;
    const bucketName = "sapienslab-storage-dev-user-files";

    try {
      // Check AWS credentials before uploading
      const credentials = await checkAwsCredentials();
      console.log("Using credentials:", credentials);

      const result = await Storage.put(s3Key, audioBlob, {
        contentType: "audio/webm",
        metadata: {
          userId,
          chatBotId,
          uploadDate: new Date().toISOString(),
        },
      });

      // Instead of getting a signed URL, construct the S3 path
      const s3Path = `s3://${bucketName}/${s3Key}`;

      onAudioUpload({
        key: s3Key,
        type: "audio/webm",
        name: `audio_${timestamp}.webm`,
        path: s3Key,
        fullPath: s3Path,
        duration: recordingTime,
      });

      setAudioBlob(null);
      setRecordingTime(0);
    } catch (error) {
      console.error("Error uploading audio:", error);
      alert("Error uploading audio message: " + error.message);
    }
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, "0")}`;
  };

  return (
    <div className="flex items-center gap-2">
      {!isRecording && !audioBlob && (
        <button
          onClick={startRecording}
          className="p-2 text-gray-400 hover:text-gray-200 transition-colors"
          title="Record audio message"
        >
          <FaMicrophone size={20} />
        </button>
      )}

      {isRecording && (
        <div className="flex items-center gap-2 bg-gray-800 rounded-full px-3 py-1">
          <div className="animate-pulse text-red-500">●</div>
          <span className="text-sm text-white">
            {formatTime(recordingTime)}
          </span>
          <button
            onClick={stopRecording}
            className="p-1 text-gray-200 hover:text-white"
            title="Stop recording"
          >
            <FaStop size={16} />
          </button>
          <button
            onClick={cancelRecording}
            className="p-1 text-gray-400 hover:text-red-400"
            title="Cancel recording"
          >
            <FaTrash size={16} />
          </button>
        </div>
      )}

      {audioBlob && !isRecording && (
        <div className="flex items-center gap-2 bg-gray-800 rounded-full px-3 py-1">
          <span className="text-sm text-white">
            {formatTime(recordingTime)}
          </span>
          <button
            onClick={uploadAudio}
            className="p-1 text-blue-400 hover:text-blue-300"
            title="Send audio message"
          >
            <FaPaperPlane size={16} />
          </button>
          <button
            onClick={cancelRecording}
            className="p-1 text-gray-400 hover:text-red-400"
            title="Cancel"
          >
            <FaTrash size={16} />
          </button>
        </div>
      )}
    </div>
  );
};

export default AudioRecorder;
