/* eslint-disable */
import awsmobile from "./aws-exports";

const hostname = window.location.hostname;
const protocol = window.location.protocol;
const port = window.location.port ? `:${window.location.port}` : "";

const isLocalhost = Boolean(
  hostname === "localhost" ||
    hostname === "[::1]" ||
    hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

// Get the base URL from the current window location
const baseUrl = `${protocol}//${hostname}${port}`;

const redirectUrl = `${baseUrl}/controlpanel`;

const updatedAwsConfig = {
  ...awsmobile,
  oauth: {
    ...awsmobile.oauth,
    domain: "sapienslab-dev.auth.us-east-1.amazoncognito.com",
    redirectSignIn: redirectUrl,
    redirectSignOut: redirectUrl,
  },
};

export default updatedAwsConfig;
