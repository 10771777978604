import React, { useEffect, useState } from "react";
import PricingTable from "./PricingTable";
import { useAuthenticator } from "@aws-amplify/ui-react";
import MySubscriptions from "./MySubscriptions";
import axios from "axios";
import { getUserId } from "../utils/userUtils";

const SubMngmt = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const [hasActiveSubscription, setHasActiveSubscription] = useState(null); // null indicates loading

  useEffect(() => {
    const checkActiveSubscriptions = async () => {
      const userId = getUserId(user);
      if (!userId) {
        console.error("No user ID available");
        setHasActiveSubscription(false);
        return;
      }

      try {
        const response = await axios.get(
          `https://d4gscm5o97.execute-api.us-east-1.amazonaws.com/prod/subscriptions/${userId}`
        );

        if (response.data.subscription_status === "active") {
          setHasActiveSubscription(true);
        } else {
          setHasActiveSubscription(false);
        }
      } catch (error) {
        console.error("Error checking subscriptions:", error);
        setHasActiveSubscription(false);
      }
    };

    checkActiveSubscriptions();
  }, [user]);

  if (hasActiveSubscription === null) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <div>Error: Unable to retrieve user information</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Subscription Management</h2>

      {hasActiveSubscription ? (
        <MySubscriptions user={user} />
      ) : (
        <PricingTable user={user} />
      )}
    </div>
  );
};

export default SubMngmt;
