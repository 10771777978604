import React, { useState, useEffect, useRef } from "react";
import { retrieve_messages } from "../utils/postAI";
import { Auth } from "aws-amplify";
import MessageList from "./chat/MessageList";
import MessageInput from "./chat/MessageInput";
import { Spinner } from "./Spinner";
import { WebSocketManager } from "../utils/websocket";
import { getUserId } from "../utils/userUtils";

const FileAttachmentPreview = ({ file, onRemove }) => {
  return (
    <div className="flex items-center gap-2 p-2 rounded-lg bg-gray-800 max-w-fit">
      <div className="flex items-center gap-2">
        <div className="w-10 h-10 flex items-center justify-center bg-pink-500 rounded-lg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
            />
          </svg>
        </div>
        <div className="flex flex-col">
          <span className="text-sm font-medium text-white">{file.name}</span>
          <span className="text-xs text-gray-400">PDF</span>
        </div>
      </div>
      <button
        onClick={onRemove}
        className="p-1 hover:bg-gray-700 rounded-full transition-colors"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
  );
};

const ChatBox = ({ chatBotId }) => {
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [userId, setUserId] = useState("");
  const [attachedFile, setAttachedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const messagesEndRef = useRef(null);
  const [wsManager, setWsManager] = useState(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    setIsLoading(true);
    Auth.currentAuthenticatedUser()
      .then((user) => {
        const userId = getUserId(user);
        if (!userId) {
          throw new Error("No user ID available");
        }
        setUserId(userId);
        document.cookie = `user_id=${userId}; path=/; SameSite=None; Secure`;
        return fetchMessages(userId, chatBotId);
      })
      .catch((err) => console.error("Error fetching user:", err))
      .finally(() => setIsLoading(false));
  }, [chatBotId]);

  useEffect(() => {
    if (userId) {
      const manager = new WebSocketManager(userId);

      manager.connect().catch((error) => {
        console.error("Failed to connect to WebSocket:", error);
      });

      const handleMessage = (response) => {
        if (response.type === "ai_response") {
          const aiMessage = {
            id: `ai_${Date.now()}`,
            type: "ai",
            msg: response.answer || "",
          };
          setMessages((prevMessages) => [...prevMessages, aiMessage]);
          scrollToBottom();
        }
      };

      manager.addMessageHandler(handleMessage);
      setWsManager(manager);

      return () => {
        manager.removeMessageHandler(handleMessage);
        manager.disconnect();
      };
    }
  }, [userId]);

  const fetchMessages = async (userId, chatBotId) => {
    try {
      const retrievedMessages = await retrieve_messages(userId, 10, chatBotId);
      setMessages(retrievedMessages);
    } catch (error) {
      console.error("Error retrieving messages:", error);
    }
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handlePostRequest();
    }
  };

  const handlePostRequest = async () => {
    if (!input.trim() && !attachedFile) return;

    const fileInfo = attachedFile
      ? `\n[File: ${attachedFile.name}](${attachedFile.fullPath})`
      : "";
    const combinedInput = `${input.trim()}${fileInfo}`;

    const messageId = `human_${Date.now()}`;
    const userMessage = {
      id: messageId,
      type: "human",
      msg: combinedInput,
    };

    setMessages((prevMessages) => [...prevMessages, userMessage]);
    scrollToBottom();

    const data = {
      human_input: combinedInput,
      file_url: attachedFile?.fullPath || null,
      file_type: attachedFile?.type || null,
      bot_id: chatBotId,
      user_id: userId,
      initial_message_type: "text",
    };

    try {
      if (wsManager && wsManager.isConnected) {
        console.log("Sending message via WebSocket:", data);
        wsManager.sendMessage(data);
      } else {
        console.error("WebSocket is not connected. Message could not be sent.");
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }

    setInput("");
    setAttachedFile(null);
  };

  const handleFileUpload = async (fileInfo) => {
    setAttachedFile(fileInfo);
  };

  const handleAudioUpload = async (audioInfo) => {
    const messageId = `human_${Date.now()}`;
    const messageText = `[File: ${audioInfo.name}](${audioInfo.fullPath})[duration: ${audioInfo.duration}]`;
    const userMessage = {
      id: messageId,
      type: "human",
      msg: messageText,
    };

    setMessages((prevMessages) => [...prevMessages, userMessage]);
    scrollToBottom();

    const data = {
      human_input: messageText,
      file_recorded_audio: audioInfo.fullPath,
      file_type: audioInfo.type,
      bot_id: chatBotId,
      user_id: userId,
      initial_message_type: "audio",
    };

    try {
      if (wsManager && wsManager.isConnected) {
        console.log("Sending audio message via WebSocket:", data);
        wsManager.sendMessage(data);
      } else {
        console.error("WebSocket is not connected. Message could not be sent.");
      }
    } catch (error) {
      console.error("Error sending audio message:", error);
    }
  };

  return (
    <div
      className="sm:col-span-12 flex flex-col bg-gray-900 text-white w-full min-h-screen bg-fixed bg-center bg-cover pt-20 z-30"
      style={{
        backgroundImage:
          chatBotId === "1"
            ? `url('https://publicsamassets.s3.amazonaws.com/img/sam_ai_sapiens.jpg')`
            : "none",
      }}
    >
      <div className="flex-grow flex flex-col mx-2 my-2 w-full relative">
        {isLoading ? (
          <div className="flex-grow flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="flex-grow overflow-y-auto mb-32">
            <MessageList
              messages={messages}
              messagesEndRef={messagesEndRef}
              chatBotId={chatBotId}
            />
          </div>
        )}
        <div className="fixed bottom-0 left-0 right-0 bg-gray-900 bg-opacity-95 p-4">
          <div className="max-w-screen-xl mx-auto">
            <div className="flex flex-col gap-2">
              {attachedFile && (
                <FileAttachmentPreview
                  file={attachedFile}
                  onRemove={() => setAttachedFile(null)}
                />
              )}
              <MessageInput
                input={input}
                handleInputChange={handleInputChange}
                handleKeyPress={handleKeyPress}
                handlePostRequest={handlePostRequest}
                handleFileUpload={handleFileUpload}
                handleAudioUpload={handleAudioUpload}
                attachedFile={attachedFile}
                setAttachedFile={setAttachedFile}
                userId={userId}
                chatBotId={chatBotId}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatBox;
